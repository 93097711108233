/* eslint-disable react/forbid-prop-types */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import BlogSidebar from '../components/BlogSidebar';
import BlogPostSummary from '../components/BlogPostSummary';
import { cleanTitle, cleanExcerpt, getFeaturedImage, formatDate } from '../utils/postUtils';

import styles from './blogList.module.scss';

const BlogList = ({ data, pageContext }) => {
  const backgroundHeaderImage =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108997/bg-blog_gewlx8.jpg';

  const { posts, currentPage, numPages } = pageContext;
  const postsWithImages = posts.map(post => {
    const imageNode = data.allAllPostsJson.edges.find(edge => edge.node.id === post.id);
    return {
      ...post,
      image: imageNode?.node?.fields?.localFeaturedImage?.childImageSharp?.fixed || null,
    };
  });

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '' : _.toString(currentPage - 1);
  const nextPage = _.toString(currentPage + 1);

  return (
    <Layout>
      <SEO
        title={`Blog${currentPage > 1 ? ` - Page ${currentPage}` : ''} | Mindset Family Therapy`}
        pathname={currentPage < 2 ? `/blog` : `/blog/${currentPage}`}
        description="Read the latest blog posts from Mindset Family Therapy, a specialized treatment practice for OCD and related anxiety disorders in Utah."
      />
      <PageHeader pageCategory="Mindset" pageName="Blog" headerImage={backgroundHeaderImage} />
      <div className={styles.blogWrapper}>
        <div className={styles.postsWrapper}>
          {_.map(postsWithImages, post => {
            const { id, slug, title, content, date, image } = post;

            let backupImage = getFeaturedImage(content);
            if (_.isNil(image) || _.isNil(backupImage)) {
              backupImage =
                'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497110189/flowers_b9l2ed.jpg';
            }

            return (
              <BlogPostSummary
                key={id}
                title={cleanTitle(title)}
                date={formatDate(date)}
                image={image}
                backupImage={backupImage}
                excerpt={cleanExcerpt(content)}
                slug={slug}
              />
            );
          })}
          <div className={styles.pageNavWrapper}>
            {!isFirst && (
              <Link to={`/blog/${prevPage}`}>
                <div className={styles.pageNavLink}>&larr;</div>
              </Link>
            )}
            {!isLast && (
              <Link to={`/blog/${nextPage}`}>
                <div className={styles.pageNavLink}>&rarr;</div>
              </Link>
            )}
          </div>
          <div className={styles.pageIdent}>
            Page {currentPage} of {numPages}
          </div>
        </div>
        <BlogSidebar />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($postIds: [String!]!) {
    allAllPostsJson(filter: { id: { in: $postIds } }) {
      edges {
        node {
          id
          fields {
            localFeaturedImage {
              childImageSharp {
                fixed(height: 200, width: 200) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

BlogList.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default BlogList;
