import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../sidebar.module.scss';

const Section = ({ children, sectionName }) => {
  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>{sectionName}</div>
      {children}
    </div>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default Section;
