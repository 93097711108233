import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './pageHeader.module.scss';

const PageHeader = ({ pageName, pageCategory, headerImage }) => {
  const category = !_.isNil(pageCategory) ? <span>{pageCategory} | </span> : null;

  const style = {
    background: `url(${headerImage}) no-repeat center center`,
    backgroundSize: 'cover',
    width: '100%',
    height: 200,
    overflow: 'hidden',
  };

  return (
    <div style={style}>
      <div className={styles.container}>
        <div className={styles.nameContainer}>
          <h1 className={styles.pageName}>
            {category} {pageName}
          </h1>
        </div>
      </div>
    </div>
  );
};

PageHeader.defaultProps = {
  pageCategory: null,
};

PageHeader.propTypes = {
  pageCategory: PropTypes.string,
  pageName: PropTypes.string.isRequired,
  headerImage: PropTypes.string.isRequired,
};

export default PageHeader;
