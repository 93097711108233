/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Dotdotdot from 'react-dotdotdot';
import { FaAngleDoubleRight } from 'react-icons/fa';

import styles from './blogPostSummary.module.scss';

const BlogPostSummary = ({ title, date, image, backupImage, excerpt, slug }) => {
  const keepReadingIcon = <FaAngleDoubleRight size={18} color="#4E839B" />;

  return (
    <div className={styles.container}>
      {image && (
        <div className={styles.postImageContainer}>
          <Img fixed={image} objectFit="cover" objectPosition="0% 50%" />
        </div>
      )}

      {!image && backupImage && (
        <div className={styles.backupImageContainer}>
          <img src={backupImage} alt="Mindset Family Therapy" />
        </div>
      )}

      <div className={styles.summaryContainer}>
        <Link to={`/blog/${slug}`}>
          <Dotdotdot clamp={2} className={styles.titleContainer} useNativeClamp>
            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={styles.date}>{date}</div>
          </Dotdotdot>
        </Link>

        <Dotdotdot clamp={5} className={styles.summary} useNativeClamp>
          <p dangerouslySetInnerHTML={{ __html: excerpt }} />
        </Dotdotdot>

        <div className={styles.readMoreWrapper}>
          <Link to={`/blog/${slug}`} className={styles.more}>
            Keep Reading {keepReadingIcon}
          </Link>
        </div>
      </div>
    </div>
  );
};

BlogPostSummary.defaultProps = {
  image: null,
};

BlogPostSummary.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  image: PropTypes.object,
  backupImage: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default BlogPostSummary;
